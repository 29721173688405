<template>
     <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br>{{desc2}}<br>{{desc3}}</p>
                    </div>
                </div>

                <div class="row" v-if="showlist">
                    <div class="col-6">
                        <div class="table-responsive">
                            <table id="datatable" class="table data-table table-striped dataTable">
                                <thead>
                                    <tr class="ligth ligth-data">
                                        <!-- <th>
                                            <div class="checkbox d-inline-block">
                                                <input type="checkbox" class="checkbox-input" id="checkbox1" v-model="allchecked" @change="allchecklist(allchecked)">
                                                <label for="checkbox1" class="mb-0"></label>
                                            </div>
                                        </th> -->
                                        <th style="max-width:300px;overflow:hidden;text-overflow: ellipsis;">구매링크</th>
                                        <th class="text-center">클릭수</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(list,index) in lists" :key="index">
                                        <!-- <td>
                                            <div class="checkbox d-inline-block">
                                                <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="list.checked">
                                                <label for="checkbox2" class="mb-0"></label>
                                            </div>
                                        </td> -->
                                        <td class="text-center" style="max-width:300px;overflow:hidden;text-overflow: ellipsis;">
                                            <input type="text" class="form-control" placeholder="구매링크입력" v-model="list.구매링크" @change="changeLink(list)">
                                        </td>
                                        <td class="text-center">{{list.클릭수}}</td>
                                        <td>
                                            <div class="d-flex justify-content-center align-items-center list-action">
                                                <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="삭제" href="javascript:void(0)" @click="deleteitem(index)">
                                                    <i class="ri-delete-bin-line mr-0"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 mb-4">
                        <button type="button" class="btn btn-success mr-2" @click="savereqlist">저장</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Page end  -->
    </div>
</template>
<script>
import * as XLSX from 'xlsx';
import axios from 'axios';
import _ from 'lodash'
import { validationMixin } from 'vuelidate'
import { required ,integer	} from 'vuelidate/lib/validators'
// import CDataTable from "@/components/common/CDataTable";
// import { VueEditor } from 'vue2-editor'
export default {
    name:'Reqpurchase',
     mixins: [validationMixin],
    validations: {
      purchaseno: { required },
      payment: { required ,integer},
      },
    components:{
    //    VueEditor,
        // CDataTable
    },
    data(){
        return{
            headers: [
                {text: '요청일', value: '요청일', align: 'center', width: 100, isSlot: true},
                {text: '요청타입', value: 'reqtype', align: 'center', width: 100, isSlot: false},
                {text: '묶음배송', value: '묶음구성여부', align: 'center', width: 100, isSlot: true},
                {text: '진행현황', value: '진행현황', align: 'center', width: 100, isSlot: false},
                {text: '제품정보', value: '상품명', align: 'left', width: 400, isSlot: true},
                {text: '구매요청수량', value: '구매요청수량', align: 'center', width: 120, isSlot: false},
                {text: '판매구성수량', value: '판매구성수량', align: 'center', width: 120, isSlot: false},
                {text: '구매링크', value: '구매링크', align: 'center', width: 100, isSlot: true},
                {text: '옵션1_중국어', value: '옵션1_중국어', align: 'center', width: 130, isSlot: false},
                {text: '옵션2_중국어', value: '옵션2_중국어', align: 'center', width: 130, isSlot: false},
                {text: 'Action', value: 'action', align: 'center', width: 150, isSlot: true}
            ],
            tableSelectedItem: [],
            obj:{},
            obj1:[],
            obj2:{},
            checkstock : true,

            savedbstatus:false,

            추가바코드:'',
            추가구매수량:'',
            구매링크:'',

            selectdate : 'today',
            startdate: this.getTodaywith(),
            enddate: this.getTodaywith(),
            selectoption:'요청일',
            SKUID: '',
            상품명: '',
            바코드: '',

            total: 0,
            구매전: 0,
            발송전: 0,
            배송중: 0,
            발송지연: 0,
            입고완료: 0,
            일괄삭제: 0,
            구매확인: 0,
            구매확정: 0,
            배송지연: 0,
            //    배송지연예정: 0,
            배송완료: 0,
            입고지연: 0,
            입고수량오류: 0,
            입고상품오류: 0,
            오류입고처리: 0,
            오류반품처리: 0,
            오류폐기처리: 0,
            오류입고대기: 0,
            반품폐기완료: 0,

            showspinner: false,

            allcheckreqlists : false,
            reqlists: [],
            reqlistsinit : [],

            title:"쿠플러스 트래픽",
            desc1:"쿠팡 상품링크를 입력해 주세요.",
            desc2:"상품링크 예시 : https://www.coupang.com/vp/products/7639030474?itemId=20292119724&vendorItemId=88253952629",
            desc3:"",

            showreqlist: false,
            showlist: true,

            checkbarcode: true,
            checktag: false,
            checkstamp: false,
            checksticker: false,

            reqtype: "로켓배송",
            showreqrocket: false,
            showreqjet: false,
            showreqgeneral: false,
            purchaseno:'',
            payment:'',
            reqcontent:'',

            allchecked: false,

            lists: [
                {
                    checked:false,
                    companyid:this.$store.state.user.companyid,
                    구매링크:"",
                    클릭수:0,
                },
                {
                    checked:false,
                    companyid:this.$store.state.user.companyid,
                    구매링크:"",
                    클릭수:0,
                },
                {
                    checked:false,
                    companyid:this.$store.state.user.companyid,
                    구매링크:"",
                    클릭수:0,
                },
                {
                    checked:false,
                    companyid:this.$store.state.user.companyid,
                    구매링크:"",
                    클릭수:0,
                },
                {
                    checked:false,
                    companyid:this.$store.state.user.companyid,
                    구매링크:"",
                    클릭수:0,
                },
                {
                    checked:false,
                    companyid:this.$store.state.user.companyid,
                    구매링크:"",
                    클릭수:0,
                },
                {
                    checked:false,
                    companyid:this.$store.state.user.companyid,
                    구매링크:"",
                    클릭수:0,
                },
                {
                    checked:false,
                    companyid:this.$store.state.user.companyid,
                    구매링크:"",
                    클릭수:0,
                },
                {
                    checked:false,
                    companyid:this.$store.state.user.companyid,
                    구매링크:"",
                    클릭수:0,
                },
                {
                    checked:false,
                    companyid:this.$store.state.user.companyid,
                    구매링크:"",
                    클릭수:0,
                },                                
            ],
            downloadlists: [],
            currentindex: '',


            uploadstatus:'excel file upload',
            cost : {
                poarrange : 0,
                exportpallet : 0,
                barcode : 0,
                sticker : 0,
                stamp : 0,
                tag : 0,
                default : 0,
            }
        }
    },
    methods: {
        changeLink(list){
            list.timestamp = Date.now();
        },
        clickProductName(list){
            if(list.상품명){
                list.상품명 = ''
            }
        },
        productNameWith(list){
            // if(confirm('변경된 한글표시사항 제품명에 따라 상품명도 변경하시겠습니까?')){
                list.상품명 = list.한글표시사항.제품명;
            // }
        },
        async copyReq(list){
            var newObj = this.deepClone(list);
            if(newObj.상품매칭.옵션1_중국어.length > 0){
                newObj.옵션1_중국어 = undefined;
            } else {
                newObj.옵션1_중국어 = '';
            }
            if(newObj.상품매칭.옵션2_중국어.length > 0){
                newObj.옵션2_중국어 = undefined;
            } else {
                newObj.옵션2_중국어 = '';
            }
            newObj.상품명init = newObj.상품명
            newObj.옵션이미지 = undefined;
            newObj.매칭상태 = '매칭필요';
            this.lists.push(newObj);
        },
        async copyRequest(list){
            if(list._id){
                delete list._id
            }
            var newObj = this.deepClone(list);
            newObj.진행현황 = '구매전';
            newObj.구매id = '';
            // this.reqinit(newObj);
            var result = await this.confirmreq(newObj);
            result.reqtype = '제트배송';
            this.lists.push(result);
            alert('복사등록완료')
        },
        addProductName(list){
            list.상품명 = list.상품명init;
        },
        getreqlists(){
            this.showlist = false;
            if(this.$store.state.trafficList.length > 0){
                this.lists = [];
				this.$store.state.trafficList.forEach(e => {
					this.lists.push(this.deepClone(e))
				})
				setTimeout(() => {
					this.showlist = true;
				}, 10);
				return
			}
            axios.post('/api/traffic/getlist',[])
            .then((res) => {
                if(res.data.요청결과 == "success"){
                    for(var i=0;i<res.data.lists.length;i++){
                        this.lists[i] = res.data.lists[i];
                    }
                    this.showlist = true;
                } else {
                    alert(res.data.요청결과)
                    this.showlist = true;
                }
            })
            .catch(console.log)
        },
        async savereqlist(){
            this.showlist = false;
            try{
                var res = await axios.post('/api/traffic/savereqlist',this.lists.filter(e => e.구매링크))
                if(res.data.요청결과 == "success"){
                    try{
                        var r = await axios.post('/api/traffic/getlist',[]);
                        if(r.data.요청결과 == "success"){
                            for(var i=0;i<r.data.lists.length;i++){
                                this.lists[i] = r.data.lists[i];
                            }
                            this.showlist = true;
                        } else {
                            console.log(r.data);
                            alert(r.data.요청결과);
                            this.showlist = true;
                        }
                    }catch(err){
                        alert("요청실패(447)")
                        console.log(err)
                    }
                } else {
                    alert(res.data.요청결과);
                    console.log(res.data);
                }
            }catch(e){
                alert("요청실패(456)")
                console.log(e)
            }
        },
        labelling(요청내역){
            if(요청내역.barcode){
                요청내역.한글표시사항 = true
            } else {
                요청내역.한글표시사항 = false
            }
        },
        qadefault(요청내역){
            if(!요청내역.default){
                if(요청내역.barcode || 요청내역.sticker || 요청내역.stamp || 요청내역.tag){
                    alert('개별 원산지 작업을 하는 경우 기본검수는 필수항목 입니다.')
                    요청내역.default = true;
                }
            }
        },
        async savereq($event,list,index){
            $event.preventDefault();
            var foo = await this.confirmlist(list)
            if(!foo){
                return
            }
            list.요청사항 = '등록완료';
            this.$bvModal.hide('modalreq' + index);

        },
        async saverequest(item){
            const index = this.reqlists.findIndex(i => i === item);
            if(this.reqlists[index].진행현황 === '입고완료'){
                alert('입고가 완료된 상품은 수정이 불가능 합니다.')
                return
            }
            if(this.reqlists[index].진행현황 === '구매전'){
                this.reqlistsinit[index] = this.deepClone(this.reqlists[index])
                this.updatereqlist([this.reqlists[index]])
            } else {
                if(this.reqlists[index]['구매요청수량'] !== this.reqlistsinit[index]['구매요청수량']){
                    alert('이미 구매 진행된 상품은 구매요청수량을 변경할 수 없습니다.')
                    return
                } else {
                    this.reqlistsinit[index] = this.deepClone(this.reqlists[index])
                    this.updatereqlist([this.reqlists[index]])
                }
            }
        },
        async updatereqlist(arr){
            if(arr.length == 1){
                axios.post('/api/reqpurchase/updatereqpurchase',arr)
                .then((res) => {
                    if(res.data.요청결과 == '변경완료'){
                        alert(res.data.요청결과)
                    } else {
                        alert(res.data.요청결과)
                        console.log(res.data)
                    }
                })
                .catch(console.log)
            } else {
                axios.post('/api/reqpurchase/updatereqpurchase',this.reqlists)
                .then((res) => {
                    if(res.data.요청결과 == '변경완료'){
                        alert(res.data.요청결과)
                    } else {
                        alert(res.data.요청결과)
                        console.log(res.data)
                    }
                })
                .catch(console.log)
            }
        },
        usergrade(){
            // console.log(this.$store.state.user)
            if(this.$store.state.user.grade == 'general'){
                this.cost.poarrange = 100
                this.cost.exportpallet = 20000
                this.cost.barcode = 500
                this.cost.sticker = 300
                this.cost.stamp = 300
                this.cost.tag = 300
                this.cost.default = 300
                this.cost.store = 5000
            }
            if(this.$store.state.user.grade == 'vip'){
                this.cost.poarrange = 100;
                this.cost.exportpallet = 20000
                this.cost.barcode = 300
                this.cost.sticker = 200
                this.cost.stamp = 200
                this.cost.tag = 300
                this.cost.default = 200
                this.cost.store = 3000
            }
            if(this.$store.state.user.grade == 'vvip'){
                this.cost.poarrange = 100;
                this.cost.exportpallet = 20000
                this.cost.barcode = 300
                this.cost.sticker = 200
                this.cost.stamp = 200
                this.cost.tag = 300
                this.cost.default = 200
                this.cost.store = 0
            }
        },
        async addlists(){
            if(this.추가바코드 == ''){
                alert('로켓 직납 요청 시 바코드 입력이 필수입니다.')
                return
            } else {
                this.showlist = false;
                var obj
                // if(this.$store.state.growthdb && !this.$store.state.growthdb.find(e => e.바코드 == this.추가바코드)){
                //     var obj = {
                //         checked : false,
                //         showspinner : false,
                //         // SKUID : '',
                //         바코드 : this.추가바코드,
                //         상품명 : '',
                //         상품명init : '',
                //         구매링크 : undefined,
                //         구매링크init : undefined,
                //         옵션1_중국어 : undefined,
                //         옵션2_중국어 : undefined,
                //         옵션이미지: undefined,
                //         매칭상태 : '매칭필요',
                //         상품매칭 : {
                //             옵션1_중국어 : [],
                //             옵션2_중국어 : [],
                //         },
                //         구매재고 : '',
                //         구매요청수량 : '',
                //         판매구성수량 : 1,
                //         묶음구성여부 : false,
                //         묶음구성수량 : '',
                //         신고영문명 : '',
                //         신고가격 : '',
                //         HSCODE : '',
                //         진행현황 : '구매전',
                //         재고동봉 : true,
                //         등록이미지 : '',
                //         요청사항 : '요청등록',
                //         reqtype : '제트배송',
                // sizeX : ob.sizeX ? ob.sizeX : '',
                //     sizeY : ob.sizeY ? ob.sizeY : '',
                //     sizeZ : ob.sizeZ ? ob.sizeZ : '',
                //     weight : ob.weight ? ob.weight : '',
                //         요청내역 : {
                //             default : true,
                //             poarrange : false,

                //             한글표시사항 : true,
                //             barcode : true,
                //             sticker : false,
                //             stamp : false,
                //             tag : false,
                //             reqcontent : '',
                //             opp : false,
                //             LDPE : false,
                //             airpacking : false,
                //             exportType : 'pallet',
                //         },
                //         한글표시사항 : {
                //             제품명 : '',
                //             '수입원/판매원' : '',
                //             제조원 : '',
                //             제조국 : '',
                //             내용량 : '',
                //             '원료명및성분명(재질)' : '',
                //             상품유형 : '',
                //             사용시주의사항 : '',
                //             사용기준 : '',
                //         },
                //         polists : [],
                //         selectpo : '',
                //     }
                //     this.lists.push(obj)
                // } else {
                //     if(this.$store.state.growthdb && this.$store.state.growthdb.find(e => e.바코드 == this.추가바코드)){
                //         var ob = this.$store.state.growthdb.find(e => e.바코드 == this.추가바코드);
                //         if(ob.요청내역){
                //             var 요청내역 = ob.요청내역
                //         } else {
                //             요청내역 = {
                //                 default : true,
                //                 poarrange : false,

                //                 boxnumber : '',
                //                 한글표시사항 : true,
                //                 barcode : true,
                //                 sticker : false,
                //                 stamp : false,
                //                 tag : false,
                //                 reqcontent : '',
                //                 opp : false,
                //                 LDPE : false,
                //                 airpacking : false,
                //                 exportType : 'pallet',
                //             }
                //         }
                //         obj = {
                //             checked : false,
                //             showspinner : false,
                //             // SKUID : ob['SKU ID'],
                //             바코드 : ob.바코드 ? ob.바코드 : '',
                //             상품명 : ob.상품명,
                //             상품명init : '',
                //             진행현황 : '구매전',
                //             구매링크 : ob.구매링크 ? ob.구매링크 : undefined,
                //             구매링크init : ob.구매링크 ? ob.구매링크 : undefined,
                //             옵션1_중국어 : ob.옵션1_중국어 ? ob.옵션1_중국어 : undefined,
                //             옵션2_중국어 : ob.옵션2_중국어 ? ob.옵션2_중국어 : undefined,
                //             옵션이미지: ob.옵션이미지 ? ob.옵션이미지 : undefined,
                //             매칭상태 : ob.매칭상태 ? ob.매칭상태 : '매칭필요',
                //             상품매칭 : {
                //                 옵션1_중국어 : [],
                //                 옵션2_중국어 : [],
                //             },
                //             구매재고 : '',
                //             구매요청수량 : '',
                //             판매구성수량: ob.판매구성수량 ? ob.판매구성수량 : 1,
                //             묶음구성여부 : ob.묶음구성여부 ? ob.묶음구성여부 : false,
                //             묶음구성수량 : ob.묶음구성수량 ? ob.묶음구성수량 : '',
                //             묶음대상바코드 : ob.묶음대상바코드 ? ob.묶음대상바코드 : '',
                //             신고영문명 : ob.신고영문명 ? ob.신고영문명 : '',
                //             신고가격 : ob['신고가격(USD)'] ? ob['신고가격(USD)'] : '',
                //             HSCODE : ob['HS-CODE'] ? ob['HS-CODE'] : '',
                //             재고동봉 : true,
                //             등록이미지 : ob.등록이미지,
                //             요청사항 : '요청등록',
                //             reqtype : '제트배송',
                // sizeX : ob.sizeX ? ob.sizeX : '',
                //     sizeY : ob.sizeY ? ob.sizeY : '',
                //     sizeZ : ob.sizeZ ? ob.sizeZ : '',
                //     weight : ob.weight ? ob.weight : '',
                //             요청내역 : 요청내역,
                //             한글표시사항 : {
                //                 제품명 : ob.상품명,
                //                 '수입원/판매원' : ob['수입원/판매원'] ? ob['수입원/판매원'] : '',
                //                 제조원 : ob.제조원 ? ob.제조원 : '',
                //                 제조국 : ob.제조국 ? ob.제조국 : '',
                //                 내용량 : ob.내용량 ? ob.내용량 : '',
                //                 '원료명및성분명(재질)' : ob['원료명및성분명(재질)'] ? ob['원료명및성분명(재질)'] : '',
                //                 상품유형 : ob.상품유형 ? ob.상품유형 : '',
                //                 사용시주의사항 : ob.사용시주의사항 ? ob.사용시주의사항 : '',
                //                 사용기준 : ob.사용기준 ? ob.사용기준 : '',
                //             },
                //             polists : [],
                //             selectpo : '',
                //         }
                //         this.lists.push(obj)
                //     } else {
                        obj = {
                            checked : false,
                            showspinner : false,
                            // SKUID : '',
                            바코드 : this.추가바코드,
                            상품명 : '',
                            상품명init : '',
                            구매링크 : undefined,
                            구매링크init : undefined,
                            옵션1_중국어 : undefined,
                            옵션2_중국어 : undefined,
                            옵션이미지: undefined,
                            매칭상태 : '매칭필요',
                            상품매칭 : {
                                옵션1_중국어 : [],
                                옵션2_중국어 : [],
                            },
                            구매재고 : '',
                            구매요청수량 : '',
                            판매구성수량 : 1,
                            묶음구성여부 : false,
                            묶음구성수량 : '',
                            묶음대상바코드 : '',
                            신고영문명 : '',
                            신고가격 : 0,
                            HSCODE : '',
                            진행현황 : '구매전',
                            재고동봉 : true,
                            등록이미지 : '',
                            요청사항 : '요청등록',
                            reqtype : '제트배송',
                            sizeX : '',
                            sizeY : '',
                            sizeZ : '',
                            weight : '',
                            요청내역 : {
                                default : true,
                                poarrange : false,

                                한글표시사항 : false,
                                barcode : false,
                                sticker : true,
                                stamp : false,
                                tag : false,
                                reqcontent : '',
                                opp : false,
                                LDPE : false,
                                airpacking : false,
                                exportType : 'box',
                            },
                            한글표시사항 : {
                                제품명 : '',
                                '수입원/판매원' : '',
                                제조원 : '',
                                제조국 : '',
                                내용량 : '',
                                '원료명및성분명(재질)' : '',
                                상품유형 : '',
                                사용시주의사항 : '',
                                사용기준 : '',
                            },
                            polists : [],
                            selectpo : '',
                        }
                        this.lists.push(obj)
                //     }
                // }

            }
            // this.reqinit(this.lists[this.lists.length-1])
            this.추가바코드 = '';
            this.추가구매수량 = '';
            this.showlist = true;
        },
        polistchange(b,list){
            list.발주수량 = b.sku수량;
            list.구매요청수량 = b.sku수량;
            list.selectpo = b.발주번호;
        },
        reqinit(obj){
            // if(obj.SKUID != ''){
            //     var cloneobj = {};
            //     this.$store.state.polist.forEach(e => {
            //         if(!e.발주상태.includes('거래명세서') && !e.발주상태.includes('발주취소')){
            //             e.발주세부내역.forEach(ele => {
            //                 if(ele.SKUID == obj.SKUID){
            //                     cloneobj = this.deepClone(e);
            //                     cloneobj.sku수량 = parseInt(ele.확정수량);
            //                     obj.polists.push(this.deepClone(cloneobj))
            //                 }
            //             })
            //         }
            //     })
            // }
            if(obj.구매링크 && this.checkstock){
                this.lists[this.lists.length-1].showspinner = true;
                this.currentindex = this.lists.length-1;
                this.sendmessage(obj.구매링크);
            } else {
                this.showlist = true;
            }
        },
        showtotal(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit;
                this.showreqlist = true;
            }, 10);
        },
        showa(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '구매전');
                this.showreqlist = true;
            }, 10);
        },
        showb(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '구매발송전');
                this.showreqlist = true;
            }, 10);
        },
        showc(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '구매발송완료');
                this.showreqlist = true;
            }, 10);
        },
        showd(){
            this.showreqlist = false;
            setTimeout(() => {
                this.reqlists = this.reqlistsinit.filter(e => e.진행현황 == '입고완료');
                this.showreqlist = true;
            }, 10);
        },
        download() {
            var arr = [];
            if(_.isEmpty(this.tableSelectedItem)){
                arr = this.reqlists
            } else {
                arr = this.tableSelectedItem
            }
            function getToday(){
                var date = new Date();
                var year = date.getFullYear();
                var month = ("0" + (1 + date.getMonth())).slice(-2);
                var day = ("0" + date.getDate()).slice(-2);

                return year + month + day;
            }
            var obj = {}
            var ar = []
            arr.forEach(e => {
                obj = {
                    요청일 : this.getdate(e.요청일),
                    진행현황 : e.진행현황,
                    등록이미지 : e.등록이미지,
                    상품명 : e.상품명,
                    'SKU ID' : e.SKUID,
                    바코드 : e.바코드,
                    구매요청수량 : e.구매요청수량,
                    구매링크 : e.구매링크,
                    옵션1_중국어 : e.옵션1_중국어,
                    옵션2_중국어 : e.옵션2_중국어,
                }
                ar.push(obj)
            })

            const workBook = XLSX.utils.book_new()
            const workSheet1 = XLSX.utils.json_to_sheet(ar)
            XLSX.utils.book_append_sheet(workBook, workSheet1, '구매대행요청내역')
            XLSX.writeFile(workBook, '구매대행요청내역' + '_' + getToday() + '.xlsx')
        },
        gettimestamp(date){
            return new Date(date).getTime()
        },
        search(){
            this.showreqlist = false;
            var start = this.gettimestamp(this.startdate)
            var end = this.gettimestamp(this.enddate) + (1000*60*60*24) - 1
            axios.post('/api/reqpurchase/getlists',{start:start,end:end})
                .then((res) => {
                    this.$store.commit('reqpurchaseupdate',res.data.reqpurchase);
                    console.log(res.data.요청결과)

                    var arr = [];
                    res.data.reqpurchase.forEach(e => {
                        e.진행현황 = e.진행현황 ? e.진행현황 : "구매전";
                        e.checked = false;
                        arr.push(this.deepClone(e))
                    })
                    console.log(arr)
                    this.구매전 = arr.filter(e => e.진행현황 === '구매전').length;
                    this.구매확인 = arr.filter(e => e.진행현황 === '구매확인').length;
                    //    this.구매확정 = arr.filter(e => e.진행현황 === '구매확정').length;
                    this.발송전 = arr.filter(e => e.진행현황 === '발송전').length;
                    this.배송중 = arr.filter(e => e.진행현황 === '배송중').length;
                    this.발송지연 = arr.filter(e => e.진행현황 === '발송지연').length;
                    this.입고완료 = arr.filter(e => e.진행현황 === '입고완료').length;
                    //    this.배송지연예정 = arr.filter(e => e.진행현황 === '배송지연예정').length;
                    this.배송지연 = arr.filter(e => e.진행현황 === '배송지연').length;
                    this.배송완료 = arr.filter(e => e.진행현황 === '배송완료').length;
                    this.입고지연 = arr.filter(e => e.진행현황 === '입고지연').length;
                    this.입고완료 = arr.filter(e => e.진행현황 === '입고완료').length;
                    this.입고수량오류 = arr.filter(e => e.진행현황 === '입고수량오류').length;
                    this.입고상품오류 = arr.filter(e => e.진행현황 === '입고상품오류').length;
                    this.오류입고처리 = arr.filter(e => e.진행현황 === '오류입고처리').length;
                    this.오류반품처리 = arr.filter(e => e.진행현황 === '오류반품처리').length;
                    this.오류폐기처리 = arr.filter(e => e.진행현황 === '오류폐기처리').length;
                    this.오류입고대기 = arr.filter(e => e.진행현황 === '오류입고대기').length;
                    this.반품폐기완료 = arr.filter(e => e.진행현황 === '반품폐기완료').length;
                    this.total = this.구매전 +
                        this.구매확인 +
                        this.발송전 +
                        this.배송중 +
                        this.발송지연 +
                        this.입고완료 +
                        this.배송지연 +
                        this.배송완료 +
                        this.입고지연 +
                        this.입고완료 +
                        this.입고수량오류 +
                        this.입고상품오류 +
                        this.오류입고처리 +
                        this.오류반품처리 +
                        this.오류폐기처리 +
                        this.오류입고대기 +
                        this.반품폐기완료;

                    this.reqlistsinit = arr;
                    this.reqlists = arr;

                    this.showreqlist = true;
                })
                .catch(e => console.log('err821' + e))
        },
        getdate(timestamp){
            var date = new Date(timestamp);
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },
        changedate(){
            var now
            var target
            if(this.selectdate == 'today'){
                this.startdate = this.getTodaywith();
                this.enddate = this.getTodaywith();
            } else {
                if(this.selectdate == 'week'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*7

                    this.startdate = this.getdate(target);
                    this.enddate = this.getTodaywith();
                } else {
                    if(this.selectdate == 'month'){
                    now = new Date(this.getTodaywith());
                    target = now - 1000*60*60*24*30
                    this.startdate = this.getdate(target);
                    this.enddate = this.getTodaywith();
                    }
                }
            }
        },
        allcheck(){
            if(this.allcheckreqlists){
                this.reqlists.forEach(e => {
                    e.checked = true;
                })
            } else {
                this.reqlists.forEach(e => {
                    e.checked = false;
                })
            }
        },
        regrequest(list,index){
            if(!list.상품명){
                alert('상품명을 먼저 입력해 주세요')
                return
            }
            this.$bvModal.show('modalreq' + index);
        },
        confirmlists(req){
            var arr = [];
            if(this.lists.filter(e => e.checked).length == 0){
                arr = this.lists
                this.lists.forEach(e => {e.checked = true})
            } else {
                arr = this.lists.filter(e => e.checked)
            }
            if(!req){
                if(arr.filter(e => !e.상품명).length > 0){
                    alert('상품명을 확인해 주세요')
                    return []
                }

                if(arr.filter(e => e.구매링크 === undefined).length > 0){
                    alert('구매링크를 모두 입력해 주세요')
                    return []
                }
                if(arr.filter(e => e.옵션1_중국어 === undefined || e.옵션2_중국어 === undefined).length > 0){
                    if(!confirm('매칭이 안된 옵션이 있습니다. 이대로 진행하시겠습니까?')){
                        return []
                    }
                }
                if(arr.filter(e => e.요청사항 !== '등록완료').length > 0){
                    alert('요청사항이 등록되지 않은 항목이 있습니다. \'요청등록\'버튼을 눌러 요청을 등록해 주세요.')
                    return []
                }
            }
            if(arr.filter(e => !e.요청내역.barcode && !e.요청내역.sticker && !e.요청내역.stamp && !e.요청내역.tag).length > 0){
                if(!confirm('원산지 작업내용이 없는 경우 통관이 불가합니다. 상품에 이미 원산지작업이 되어 있습니까?')){
                    return []
                }
            }
            if(arr.filter(e => !e.신고영문명).length > 0){
                alert("요청내역 중 신고영문명을 작성해주세요. ex)여성신발 -> women's shoes ")
                return []
            }
            // if(arr.filter(e => !e.신고가격).length > 0){
            //     alert("요청내역 중 신고가격을 USD로 환산하여 작성해주세요. ex) 2달러 -> 2 ")
            //     return []
            // }
            if(arr.filter(e => !e.HSCODE).length > 0){
                alert("요청내역 중 HS-CODE를 작성해주세요. ex) 6303.92-0000 ")
                return []
            }
            if(arr.filter(e => e.HSCODE.length !== 12).length > 0){
                alert("HS-CODE를 Full name으로 작성해주세요. ex) 6303.92-0000 ")
                return []
            }
            if(arr.filter(e => e.구매요청수량 == 0 || !e.구매요청수량).length > 0){
                alert("구매요청수량이 작성안된 제품이 있습니다.")
                return []
            }
            if(arr.filter(e => parseInt(e.구매재고) <  e.구매요청수량).length > 0){
                if(!confirm("구매요청수량보다 구매할수 있는 재고가 적은 상품이 있습니다. 그래도 구매요청 하시겠습니까?")){
                    return []
                }
            }
            if(arr.filter(e => this.reqlists.filter(ele => ele.진행현황 == '구매전' && ele.구매링크 == e.구매링크 && (e.옵션1_중국어 && ele.옵션1_중국어 ? e.옵션1_중국어 == ele.옵션1_중국어 : true) && (e.옵션2_중국어 && ele.옵션2_중국어 ? e.옵션2_중국어 == ele.옵션2_중국어 : true))).length > 0){
                if(!confirm("동일한 제품이 구매전인 구매대행요청내역이 존재합니다. 동일주문일 경우 해당 요청내역에서 수량등의 정보를 변경하실 수 있습니다. 그래도 추가 요청하시겠습니까?")){
                    return []
                }
            }
            return arr
        },
        confirmlist(list){
            if(!list.바코드){
                if(!confirm('바코드가 없는 경우 로켓그로스 출고가 불가능합니다.')){
                    return false
                }
            }
            if(!list.신고영문명){
                alert("요청내역 중 신고영문명을 작성해주세요. ex)여성신발 -> women's shoes ")
                return false
            }
            // if(!list.신고가격){
            //     alert("요청내역 중 신고가격을 USD로 환산하여 작성해주세요. ex) 2달러 -> 2 ")
            //     return false
            // }
            if(!list.HSCODE){
                alert("요청내역 중 HS-CODE를 작성해주세요. ex) 6303.92-0000 ")
                return false
            }
            if(list.HSCODE.length !== 12){
                alert("HS-CODE를 Full name으로 작성해주세요. ex) 6303.92-0000 ")
                return false
            }
            if(list.구매요청수량 == 0 || !list.구매요청수량){
                alert("구매요청수량을 작성해 주세요.")
                return false
            }
            if(list.판매구성수량 == 0 || !list.판매구성수량){
                alert("판매구성수량을 작성해 주세요.")
                return false
            }
            list.한글표시사항.제품명 = list.상품명;
            if(Object.keys(list.한글표시사항).find(e => list.한글표시사항[e].length == 0 && e !== '제품명')){
                alert('한글표시사항을 모두 작성해 주세요')
                return
            }
            return true
        },
        async requestpurchase(){

            var list = await this.confirmlists(false)
            if(list.length == 0){
                alert('요청할 목록이 없습니다.')
                return
            }
            // if(confirm('현재 요청하신 정보를 상품DB에 저장하시겠습니까? (저장 이후에 요청시 구매링크와 옵션이 자동 매핑되며, SKUID가 없는 제품은 저장되지 않습니다.)')){
            //     await this.saveproductdb()
            // }
            var cnt = list.length;
            var arr = [];
            var obj = {};
            var now = Date.now();
            list.forEach(e => {
                obj = this.deepClone(e);
                obj.companyid = this.$store.state.user.companyid;
                obj.요청일 = now;
                obj.구매_id = '';
                arr.push(this.deepClone(obj));
            })
            if(confirm("총 " + cnt + "건을 구매대행 요청하시겠습니까?")){
                axios.post('/api/reqpurchase/requestpurchase',arr)
                .then((res) => {
                    this.$store.commit('reqpurchaseupdate',res.data.reqpurchase);
                    alert(res.data.요청결과)
                    var ar = this.lists.filter(e => !e.checked)
                    this.lists = ar;
                })
                .catch(console.log)
            }
        },
        async saveproductdb(){

            var list = await this.confirmlists(false)
            if(list.length == 0){
                alert('업데이트할 목록이 없습니다.')
                return
            }
            // this.savedbstatus = true;
            var arr = list
            console.log(list)
            axios.post('/api/reqpurchase/updateproductdb',arr)
            .then((res) => {
                this.$store.commit('productdbupdate',res.data.productdb);
                alert(res.data.요청결과)
            })
            .catch(console.log)
        },
        deleteindexfromarray(array,index){
            return array.slice(0,index).concat(array.slice(index+1))
        },
        deleteitem(index){
            this.lists[index].구매링크 = "";
            this.lists[index].클릭수 = "";
        },
        confirmexcelDownFunc(){
            if(this.reqtype == '로켓배송'){
                this.excelDownFunc()
                return
            }
            if(this.reqtype == '제트배송'){
                this.excelDownFunc()
                return
            }
            if(this.reqtype == '일반LCL'){
                if(confirm('일반LCL에서 SKUID는 10000000 이하의 숫자로 임의로 기입하시면 됩니다.')){
                    this.excelDownFunc()
                    return
                }
            }
        },
        modalreq(index){
            return 'modalreq' + index;
        },
        reset(list){
            list.상품명 = '';
            list.상품명init = '';
            list.옵션1_중국어 = undefined;
            list.옵션2_중국어 = undefined;
            list.상품매칭.옵션1_중국어 = [];
            list.상품매칭.옵션2_중국어 = [];
            list.구매링크 = undefined;
            list.구매링크init = '';
        },
        rematching(list,index){
            if(!list.구매링크){
                alert('구매링크를 입력해 주세요')
                return
            }
            list.옵션1_중국어 = undefined;
            list.옵션2_중국어 = undefined;
            list.상품매칭.옵션1_중국어 = [];
            list.상품매칭.옵션2_중국어 = [];
            this.matchoption1(index,list.구매링크)
        },
        fileupload(){
            this.$refs.pdbupload.value = '';
            this.$refs.pdbupload.click();
        },
        option1matching(a,list){
            // this.showlist = false;
            if(a.imageUrl){
                // if(!list.등록이미지){
                    list.등록이미지 = a.imageUrl;
                // }
                list.옵션이미지 = a.imageUrl;
            }
            list.옵션1_중국어 = a.name;


            // if(this.checkstock){
            if(list.옵션2_중국어 == ''){
                var properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1).quantity);
                if(list.구매재고 == 0){
                    alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                } else {
                    alert('구매 가능한 재고수량 : ' + list.구매재고)
                }
                list.매칭상태 = '매칭완료';
            } else {
                if(list.옵션2_중국어 !== undefined){
                    properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                    var properties2 = list.상품매칭.옵션2_중국어.find(e => e.name == list.옵션2_중국어).properties;
                    list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).quantity);
                    if(list.구매재고 == 0){
                        alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                    } else {
                        alert('구매 가능한 재고수량 : ' + list.구매재고)
                    }
                    list.매칭상태 = '매칭완료';
                }
            }
            if(list.등록이미지 == '' && list.옵션이미지 == ''){
                list.등록이미지 = list.item.pic_url;
                list.옵션이미지 = list.item.pic_url;
            }
            // this.showlist = true;
            // }
        },
        option2matching(a,list){
            // this.showlist = false;
            if(a.imageUrl){
                list.등록이미지 = a.imageUrl;
            }
            list.옵션2_중국어 = a.name;

            // if(this.checkstock){

            if(list.상품매칭.옵션1_중국어 && list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어)){
                var properties1 = list.상품매칭.옵션1_중국어.find(e => e.name == list.옵션1_중국어).properties;
                var properties2 = list.상품매칭.옵션2_중국어.find(e => e.name == list.옵션2_중국어).properties;
                list.구매재고 = parseInt(list.item.skus.sku.find(e => e.properties == properties1 + ";" + properties2).quantity);
                if(list.구매재고 == 0){
                    alert('구매 가능한 재고수량이 없습니다. 판매자에게 확인해 주세요.')
                } else {
                    alert('구매 가능한 재고수량 : ' + list.구매재고)
                }
                list.매칭상태 = '매칭완료'
            }

            // }
            if(list.등록이미지 == '' && list.옵션이미지 == ''){
                list.등록이미지 = list.item.pic_url;
                list.옵션이미지 = list.item.pic_url;
            }
            // this.showlist = true;
        },
        sendmessage(url){
            window.postMessage({greeting: "importproductinfo",url:url},"*",)
        },
        async getProductInfo(구매링크){
            var productNo = 구매링크.slice(구매링크.search('offer/')+6,구매링크.search('.html'));
            console.log(productNo)
            try{
                var res = await axios.post('/api/reqpurchase/getProductInfo',[productNo])
                return res.data.result[0].item
            }catch(e){
                console.log('err821' + e);
                return false
            }
        },
        async confirmreq(obj){
            if(!obj.구매링크){
                obj.showspinner = false;
                return obj
            }
            obj.구매링크init = obj.구매링크;
            obj.매칭상태 = '매칭필요'
            var item = await this.getProductInfo(obj.구매링크);
            console.log(item)

            if(item.format_check == 'fail'){
                obj.상품매칭.옵션1_중국어 = [];
                obj.상품매칭.옵션2_중국어 = [];
                obj.옵션1_중국어 = '';
                obj.옵션2_중국어 = '';
                obj.구매재고 = '구매불가';
                obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : item.pic_url;
                obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : item.pic_url;
                obj.매칭상태 = '매칭필요';
                obj.showspinner = false;
                return obj
            }

            var option1array = [];
            var option2array = [];
            if(item.props_list && Object.keys(item.props_list).length > 0) {
                item.skus.sku.forEach(e => {
                    if (e.properties.includes(";")) {
                        var properties1 = e.properties.split(';')[0];
                        var properties2 = e.properties.split(';')[1];
                        if (!option1array.find(ele => ele.properties == properties1)) {
                            var name = e.properties_name.split(";" + properties2)[0].replace(properties1 + ":", "")
                            name = name.slice(name.search(":") + 1)
                            option1array.push({
                                properties: properties1,
                                name: name,
                                imageUrl: item.props_img[properties1] ? item.props_img[properties1] : '',
                            })
                        }
                        if (!option2array.find(ele => ele.properties == properties2)) {
                            name = e.properties_name.split(";" + properties2)[1].slice(1);
                            name = name.slice(name.search(":") + 1)
                            option2array.push({
                                properties: properties2,
                                name: name,
                                imageUrl: item.props_img[properties2] ? item.props_img[properties2] : '',
                            })
                        }
                    } else {
                        name = e.properties_name.replace(e.properties + ":", "");
                        name = name.slice(name.search(":") + 1)
                        option1array.push({
                            properties: e.properties,
                            name: name,
                            imageUrl: item.props_img[e.properties] ? item.props_img[e.properties] : '',
                        })
                    }
                })
            }

            if(option1array.length == 0){
                obj.상품매칭.옵션1_중국어 = [];
                obj.상품매칭.옵션2_중국어 = [];
                obj.옵션1_중국어 = '';
                obj.옵션2_중국어 = '';
                obj.구매재고 = parseInt(item.num);
                obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : item.pic_url;
                obj.옵션이미지 = obj.옵션이미지 ? obj.옵션이미지 : item.pic_url;
                obj.매칭상태 = '매칭완료'
            } else {
                if(option2array.length == 0){
                    var imgUrl = '';
                    if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                        obj.옵션1_중국어 = undefined;
                        obj.옵션이미지 = undefined;
                        obj.구매재고 = 0;
                        obj.item = item
                    } else {
                        var option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                        console.log(option1properties)
                        obj.구매재고 = parseInt(item.skus.sku.find(e => e.properties == option1properties).quantity);
                        if(item.prop_imgs.prop_img.length > 0){
                            if(item.prop_imgs.prop_img.find(e => e.properties == option1properties)){
                                imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option1properties).url;
                            } else {
                                if(obj.옵션이미지){
                                    imgUrl = obj.옵션이미지;
                                } else {
                                    imgUrl = '';
                                }
                            }
                            
                        }
                        obj.옵션이미지 = imgUrl;
                        obj.매칭상태 = '매칭완료'
                    }
                    obj.상품매칭.옵션1_중국어 = option1array;
                    obj.상품매칭.옵션2_중국어 = [];
                    obj.옵션2_중국어 = '';
                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
                } else {
                    imgUrl = '';
                    if(!option1array.find(e => e.name == obj.옵션1_중국어)){
                        obj.옵션1_중국어 = undefined;
                        obj.옵션이미지 = undefined;
                        obj.구매재고 = 0;
                        obj.item = item
                    } else {
                        option1properties = option1array.find(e => e.name == obj.옵션1_중국어).properties;
                        if(item.prop_imgs.prop_img && item.prop_imgs.prop_img.find(e => e.properties == option1properties)){
                            imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option1properties).url;
                        }
                        obj.옵션이미지 = imgUrl;
                        if(!option2array.find(e => e.name == obj.옵션2_중국어)){
                            obj.옵션2_중국어 = undefined;
                            obj.구매재고 = 0;
                            obj.item = item
                        } else {
                            var option2properties = option2array.find(e => e.name == obj.옵션2_중국어).properties;
                            if (!item.skus.sku.find(e => e.properties == (option1properties + ';' + option2properties))) {
                                obj.옵션이미지 = undefined
                                obj.옵션1_중국어 = undefined;
                                obj.옵션2_중국어 = undefined;
                                obj.매칭상태 = '매칭필요'
                                obj.구매가격 = ''
                            } else {
                                obj.구매재고 = parseInt(item.skus.sku.find(e => e.properties == (option1properties + ';' + option2properties)).quantity);
                            }
                            if(item.prop_imgs.prop_img && item.prop_imgs.prop_img.find(e => e.properties == option2properties)){
                                imgUrl = item.prop_imgs.prop_img.find(e => e.properties == option2properties).url;
                            }
                            obj.옵션이미지 = imgUrl;
                            obj.매칭상태 = '매칭완료'
                        }
                    }
                    obj.상품매칭.옵션1_중국어 = option1array;
                    obj.상품매칭.옵션2_중국어 = option2array;
                    obj.등록이미지 = obj.등록이미지 ? obj.등록이미지 : imgUrl;
                }
            }
            if(!obj.등록이미지 && !option1array.find(e => e.imageUrl) && !option1array.find(e => e.imageUrl)){
                obj.등록이미지 = item.pic_url;
            }
            obj.showspinner = false;
            return obj
        },
        async matchoption1(index,구매링크){
            this.lists[index].showspinner = true;
            this.lists[index].구매링크 = 구매링크;
            this.currentindex = index;
            // setTimeout(() => {this.sendmessage(구매링크);},1)
            var newObj = await this.confirmreq(this.lists[index]);
            // console.log(newObj)
            // this.showlist = false
            this.lists[index] = newObj;
            console.log(this.lists[this.currentindex])

            // axios.post('/api/reqpurchase/geturlinfo',{
            //     url : 구매링크
            // })
            // .then((res) => {
            //     if(res.data.요청결과 == 'success'){
            //         console.log(res.data.result)
            //         var obj = {}
            //         res.data.result.option1array.forEach(e => {
            //             obj = {
            //                 image : e.imageUrl,
            //                 optionname : e.name,
            //             }
            //             list.상품매칭.옵션1_중국어.push(obj)
            //         })
            //         res.data.result.option2array.forEach(e => {
            //             obj = {
            //                 image : e.imageUrl,
            //                 optionname : e.name,
            //             }
            //             list.상품매칭.옵션1_중국어.push(obj)
            //         })

            //     } else {
            //         alert('error777 :' + res.data.요청결과)
            //     }
            // })
            // .catch(console.log)
        },
        matchclass(a){
            if(a == '매칭완료'){
                return 'badge badge-success';
            } else {
                return 'badge badge-warning';
            }
        },
        reqclass(a){
            if(a == '등록완료'){
                return 'badge badge-success';
            } else {
                return 'badge badge-warning';
            }
        },
        excelDownFunc(){
            var arr = [];
            var myHeader = ["SKUID","구매요청수량"];

            var dataWS = XLSX.utils.json_to_sheet(arr, {header : myHeader});

            const range = XLSX.utils.decode_range(dataWS['!ref']);
            range.e['c'] = myHeader.length - 1;
            dataWS['!ref'] = XLSX.utils.encode_range(range);

            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, dataWS, '구매대행요청양식');
            // 엑셀 파일을 내보낸다.
            XLSX.writeFile(wb, '구매대행요청양식.xlsx');
        },
        filterByStatus(status) {
            this.reqlists = this.reqlistsinit.filter(e => e.진행현황 === status);
        },
        uploadfile(event) {
            var obj = {};
            var ob = {};
            var op1 = '';
            var op2 = '';
            this.showlist = false;
            this.lists = [];
            // var 주문번호,판매처,배송단가,할인단가,운송장번호,주문날짜,주문상태;
            setTimeout(() => {
                const file = event.target.files[0];
                let reader = new FileReader();
                reader.onload = (e) => {
                    // let data = reader.result;
                    let data = e.target.result;
                    let workbook = XLSX.read(data, {type: 'binary'});
                    workbook.SheetNames.forEach(sheetName => {
                        const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                        console.log(roa)
                        if(this.reqtype == '일반LCL'){
                            roa.forEach(e => {
                                if(this.$store.state.productdb.find(ele => parseInt(ele['SKU ID']) == parseInt(e.SKUID))){
                                    ob = this.$store.state.productdb.find(ele => parseInt(ele['SKU ID']) == parseInt(e.SKUID))
                                    // console.log(e)
                                    // console.log(ob)
                                    if(ob.옵션1_중국어 || ob.옵션1_중국어 == ''){
                                        op1 = ob.옵션1_중국어
                                    } else {
                                        op1 = undefined
                                    }
                                    if(ob.옵션2_중국어 || ob.옵션2_중국어 == ''){
                                        op2 = ob.옵션2_중국어
                                    } else {
                                        op2 = undefined
                                    }
                                    obj = {
                                        checked : false,
                                        showspinner : false,
                                        SKUID : e.SKUID,
                                        바코드 : ob.바코드 ? ob.바코드 : '',
                                        상품명 : ob.상품명,
                                        구매링크 : ob.구매링크 ? ob.구매링크 : undefined,
                                        옵션1_중국어 : op1,
                                        옵션2_중국어 : op2,
                                        매칭상태 : ob.구매링크 !== undefined || op1 !== undefined || op2 !== undefined ? '매칭완료' : '매칭필요',
                                        상품매칭 : {
                                            옵션1_중국어 : [],
                                            옵션2_중국어 : [],
                                        },
                                        구매요청수량 : e.구매요청수량,
                                        등록이미지 : ob.등록이미지,
                                        checkbarcode : this.checkbarcode,
                                        checksticker : this.checksticker,
                                        checkstamp : this.checkstamp,
                                        checktag : this.checktag,
                                        reqcontent : this.reqcontent,
                                        reqtype : this.reqtype,
                                        sizeX : ob.sizeX ? ob.sizeX : '',
                                        sizeY : ob.sizeY ? ob.sizeY : '',
                                        sizeZ : ob.sizeZ ? ob.sizeZ : '',
                                        weight : ob.weight ? ob.weight : '',
                                    }
                                    this.lists.push(obj)
                                } else {
                                    if(e.옵션1_중국어 || e.옵션1_중국어 == ''){
                                        op1 = e.옵션1_중국어
                                    } else {
                                        op1 = undefined
                                    }
                                    if(e.옵션2_중국어 || e.옵션2_중국어 == ''){
                                        op2 = e.옵션2_중국어
                                    } else {
                                        op2 = undefined
                                    }
                                    obj = {
                                        checked : false,
                                        showspinner : false,
                                        SKUID : e.SKUID,
                                        바코드 : e.바코드 ? e.바코드 : '',
                                        상품명 : e.상품명,
                                        구매링크 : e.구매링크 ? e.구매링크 : undefined,
                                        옵션1_중국어 : op1,
                                        옵션2_중국어 : op2,
                                        매칭상태 : e.구매링크 !== undefined || op1 !== undefined || op2 !== undefined ? '매칭완료' : '매칭필요',
                                        상품매칭 : {
                                            옵션1_중국어 : [],
                                            옵션2_중국어 : [],
                                        },
                                        구매요청수량 : e.구매요청수량,
                                        등록이미지 : e.등록이미지,
                                        checkbarcode : this.checkbarcode,
                                        checksticker : this.checksticker,
                                        checkstamp : this.checkstamp,
                                        checktag : this.checktag,
                                        reqcontent : this.reqcontent,
                                        reqtype : this.reqtype,
                                        sizeX : '',
                                        sizeY : '',
                                        sizeZ : '',
                                        weight : '',
                                    }
                                    this.lists.push(obj)
                                }
                            })
                        } else {
                            roa.forEach(e => {
                                if(this.$store.state.productdb.find(ele => parseInt(ele['SKU ID']) == parseInt(e.SKUID))){
                                    ob = this.$store.state.productdb.find(ele => parseInt(ele['SKU ID']) == parseInt(e.SKUID))
                                    // console.log(e)
                                    // console.log(ob)
                                    if(ob.옵션1_중국어 || ob.옵션1_중국어 == ''){
                                        op1 = ob.옵션1_중국어
                                    } else {
                                        op1 = undefined
                                    }
                                    if(ob.옵션2_중국어 || ob.옵션2_중국어 == ''){
                                        op2 = ob.옵션2_중국어
                                    } else {
                                        op2 = undefined
                                    }
                                    obj = {
                                        checked : false,
                                        showspinner : false,
                                        SKUID : e.SKUID,
                                        바코드 : ob.바코드,
                                        상품명 : ob.상품명,
                                        구매링크 : ob.구매링크 ? ob.구매링크 : undefined,
                                        옵션1_중국어 : op1,
                                        옵션2_중국어 : op2,
                                        매칭상태 : ob.구매링크 !== undefined || op1 !== undefined || op2 !== undefined ? '매칭완료' : '매칭필요',
                                        상품매칭 : {
                                            옵션1_중국어 : [],
                                            옵션2_중국어 : [],
                                        },
                                        구매요청수량 : e.구매요청수량,
                                        등록이미지 : ob.등록이미지,
                                        checkbarcode : this.checkbarcode,
                                        checksticker : this.checksticker,
                                        checkstamp : this.checkstamp,
                                        checktag : this.checktag,
                                        reqcontent : this.reqcontent,
                                        reqtype : this.reqtype,
                                        sizeX : ob.sizeX ? ob.sizeX : '',
                                        sizeY : ob.sizeY ? ob.sizeY : '',
                                        sizeZ : ob.sizeZ ? ob.sizeZ : '',
                                        weight : ob.weight ? ob.weight : '',
                                    }
                                    // console.log(obj)
                                    this.lists.push(obj)
                                } else {
                                    alert('로켓/제트 상품은 상품DB를 먼저 등록해주세요')
                                    return
                                }
                            })
                        }
                        this.showlist = true;
                    });
                };
                reader.readAsBinaryString(file);
            }, 10);
        },
        modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
        downloadxlsx(lists){
            if(lists.length > 0){
                var createpo = [];
                var totalpo = [];
                //this.lists, setting값에 따라 createpo 업데이트
                var obj = {};
                var link
                var option1
                var option2
                lists.forEach(e => {
                    e.발주세부내역.forEach(ele => {
                        link = '';
                        option1 = '';
                        option2 = '';
                        this.$store.state.productdb.some(element => {
                            if(element.바코드 == ele.바코드){
                                link = element.구매링크;
                                option1 = element.옵션1_중국어;
                                option2 = element.옵션2_중국어;
                                return (element.바코드 == ele.바코드)
                            }
                        })
                        if(ele.구매필요수량>0){
                            if(link == ''){
                                alert('"' + ele.바코드 + '" 제품의 구매링크 정보가 없습니다.')
                            } else {
                                obj = {
                                    '订单号':e.발주번호,
                                    '商品名称':ele.상품명,
                                    '商品数量(件)':ele.구매필요수량,
                                    '商品规格1（如：颜色）': option1,
                                    '商品规格2（如：尺码）': option2,
                                    '收件人-姓名': this.CreatePo_수취인,
                                    '收件人-手机': this.CreatePo_연락처,
                                    '收件人-省':this.CreatePo_주소_성,
                                    '收件人-市':this.CreatePo_주소_시,
                                    '收件人-区':this.CreatePo_주소_구,
                                    '收件人-详细地址':this.CreatePo_상세주소,
                                    '收货地址（省/市/区/详细地址合并的收货地址）':'',
                                    '买家留言':ele.SKUID,
                                    '1688商品链接/1688商品id':link.slice(30,link.search('.html')),
                                    '分销or现货（默认或者填1走分销，0走现货）':0,
                                }
                                totalpo.push(obj);
                            }
                        }
                    })
                })

                //1688id순으로 정렬 (중국내배송비최소화)
                totalpo.sort(function(a,b){
                    if (a['1688商品链接/1688商品id'] < b['1688商品链接/1688商品id']){
                        return -1;
                    }
                    if (a['1688商品链接/1688商品id'] > b['1688商品链接/1688商品id']){
                        return 1;
                    }
                    return 0;
                })

                //주문번호 변경 (발주번호 --> timestamp0001)
                var matchpo = [];
                var matchobj = {};
                var ponum = '';

                var now = Date.now()
                var n = 0;
                totalpo.forEach((e,i) => {
                    matchobj = {};
                    if(i > 0){
                        if(totalpo[i-1]['1688商品链接/1688商品id'] != totalpo[i]['1688商品链接/1688商品id']){
                            n++
                        }
                    }
                    ponum = now.toString() + (n+1).toString().padStart(4,'0')
                    matchobj = {
                        쿠플러스주문번호: ponum,
                        발주번호: e['订单号'],
                        SKUID: e['买家留言'],
                        상품명: e['商品名称'],
                        구매수량: e['商品数量(件)'],
                        상품번호: e['1688商品链接/1688商品id'],
                        option1: e['商品规格1（如：颜色）'],
                        option2: e['商品规格2（如：尺码）']
                    };
                    matchpo.push(matchobj);
                    e['订单号'] = ponum;
                })
                //axios matchpo

                axios.post('/api/addpurchase/addmatchpo',matchpo)
                .then((res) => {
                    this.$store.commit('matchpoupdate',res.data.matchpo);
                    alert(res.data.요청결과)
                })
                .catch(console.log)


                //1688po를 100개씩 잘라서 createpo array에 저장
                console.log(totalpo)
                var i
                var j
                var cnt = totalpo.length;

                for(j=0;j<cnt;j++){
                    if(totalpo.length < 100){
                        createpo.push(totalpo)
                        break
                    }
                    for(i=100;i>0;i--){
                        if(totalpo[i][['1688商品链接/1688商品id']] != totalpo[i-1][['1688商品链接/1688商品id']]){
                            console.log(totalpo.slice(0,i))
                            createpo.push(totalpo.slice(0,i))
                            totalpo = totalpo.slice(i,totalpo.length)
                            console.log(totalpo)
                            break
                        } else {
                            if(i==1){
                                createpo.push(totalpo.slice(0,100))
                                totalpo = totalpo.slice(100,totalpo.length)
                            }
                        }
                    }
                }

                //test
                // const workBook = XLSX.utils.book_new()
                // const workSheet1 = XLSX.utils.json_to_sheet(totalpo)
                // const workSheet2 = XLSX.utils.json_to_sheet(matchpo)
                // XLSX.utils.book_append_sheet(workBook, workSheet1, '1688주문서')
                // XLSX.utils.book_append_sheet(workBook, workSheet2, 'matching')
                // XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '.xlsx')


                //다운로드
                for(i=0;i<createpo.length;i++){
                    const workBook = XLSX.utils.book_new()
                    const workSheet = XLSX.utils.json_to_sheet(createpo[i])
                    XLSX.utils.book_append_sheet(workBook, workSheet, '1688주문서')
                    XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '_' + i.toString() + '.xlsx')
                }
            } else {
                alert('생성할 주문서가 없습니다.')
            }
        },
        downloadtotal(){
            //this.lists를 1688주문서로 변환해서 다운로드
            this.downloadxlsx(this.lists);
        },
        downloadselect(){
            var selectlists = [];
            this.lists.forEach(e => {
                if(e.checked == true){
                    selectlists.push(e)
                }
            })
            this.downloadxlsx(selectlists);
        },
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},


        deletelist(item){
            const index = this.reqlists.findIndex(i => i === item);
            var newarr = [];
            var delarr = [];
            this.reqlists.forEach((e,i) => {
                if(i !== index){
                    newarr.push(this.deepClone(e))
                } else {
                    delarr.push(this.deepClone(e))
                }
            })
            this.reqlists = newarr;
            if(confirm('삭제 후에는 복구가 불가능합니다. 그래도 삭제하시겠습니까?')){
                this.deletereqlists(delarr);
            }
        },
        async deletereqlists(delarr){
            axios.post('/api/reqpurchase/deletereqpurchase',delarr)
            .then((res) => {
                if(res.data.요청결과 == '삭제완료'){
                    alert(res.data.요청결과)
                } else {
                    alert(res.data.요청결과)
                    console.log(res.data)
                }
            })
            .catch(console.log)
        },
        deletedetail(발주번호,SKUID){
            var newarr = [];
            var newobj = {};
            this.lists.forEach(e => {
                if(e.발주번호 != 발주번호){
                    newarr.push(e);
                } else {
                    newobj = this.deepClone(e);
                    newobj.발주세부내역 = [];
                    e.발주세부내역.forEach(ele => {
                        if(ele.SKUID != SKUID){
                            newobj.발주세부내역.push(ele);
                        }
                    });
                    newarr.push(newobj);
                }
            })
            this.lists = newarr;
        },
        allchecklist(allchecked){
            if(allchecked == true){
                this.lists.forEach(e => {
                    e.checked = true;
                })
            } else {
                this.lists.forEach(e => {
                    e.checked = false;
                })
            }
        },
        allcheckdetaillist(allcheckdetail){
            if(allcheckdetail == true){
                this.detaillists.forEach(e => {
                    e.checked = true;
                })
            } else {
                this.detaillists.forEach(e => {
                    e.checked = false;
                })
            }
        },
        getTodaywith(){
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },
        getToday(){
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + month + day;
        },
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime() / 1000
        },
        downloadpo(){
            if(this.downloadlists.length > 0){
                var createpo = [];
                var totalpo = this.downloadlists;


                //1688id순으로 정렬 (중국내배송비최소화)
                totalpo.sort(function(a,b){
                    if (a['1688商品链接/1688商品id'] < b['1688商品链接/1688商品id']){
                        return -1;
                    }
                    if (a['1688商品链接/1688商品id'] > b['1688商品链接/1688商品id']){
                        return 1;
                    }
                    return 0;
                })
                //1688id순으로 정렬 (중국내배송비최소화)
                totalpo.sort(function(a,b){
                    if (a.판매처 < b.판매처){
                        return -1;
                    }
                    if (a.판매처 > b.판매처){
                        return 1;
                    }
                    return 0;
                })


                //1688po를 100개씩 잘라서 createpo array에 저장
                console.log(totalpo)
                var i
                var j
                var cnt = totalpo.length;

                for(j=0;j<cnt;j++){
                    if(totalpo.length < 100){
                        createpo.push(totalpo)
                        break
                    }
                    for(i=100;i>0;i--){
                        if(totalpo[i][['1688商品链接/1688商品id']] != totalpo[i-1][['1688商品链接/1688商品id']]){
                            console.log(totalpo.slice(0,i))
                            createpo.push(totalpo.slice(0,i))
                            totalpo = totalpo.slice(i,totalpo.length)
                            console.log(totalpo)
                            break
                        } else {
                            if(i==1){
                                createpo.push(totalpo.slice(0,100))
                                totalpo = totalpo.slice(100,totalpo.length)
                            }
                        }
                    }
                }

                //test
                // const workBook = XLSX.utils.book_new()
                // const workSheet1 = XLSX.utils.json_to_sheet(totalpo)
                // const workSheet2 = XLSX.utils.json_to_sheet(matchpo)
                // XLSX.utils.book_append_sheet(workBook, workSheet1, '1688주문서')
                // XLSX.utils.book_append_sheet(workBook, workSheet2, 'matching')
                // XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '.xlsx')


                //다운로드
                for(i=0;i<createpo.length;i++){
                    const workBook = XLSX.utils.book_new()
                    const workSheet = XLSX.utils.json_to_sheet(createpo[i])
                    XLSX.utils.book_append_sheet(workBook, workSheet, '1688주문서')
                    XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '_' + i.toString() + '.xlsx')
                }
            } else {
                alert('생성할 주문서가 없습니다.')
            }
        },
        submit() {
            if(this.poinfocnt == 0){
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.lists = [];
                    this.submitStatus = true
                    var obj = {};
                    var sobj = {};
                    var ssobj = {};
                    var robj = {};
                    var addobj = {};
                    var addcnt = 0;
                    var rstockcnt = 0;
                    var stockcnt = 0;
                    var sstockcnt = 0;
                    var elcnt = 0;
                    var existaddcnt = 0;
                    var arrangecnt = 0;
                    var notarrangecnt = 0;
                    var pstockcnt = 0;
                    //setting값에 따라 lists 업데이트

                    //날짜로 this.totallists를 추려서 저장
                    this.totallists = this.$store.state.polist.filter(e => this.timestamp(this.startdate) <= this.timestamp(e.발주일시.slice(0,10)) && this.timestamp(this.enddate) >= this.timestamp(e.발주일시.slice(0,10)));
                    //this.totallists에서 lists 추출
                    this.totallists.forEach(e => {
                        e.발주세부내역.forEach(ele => {
                            arrangecnt = 0;
                            notarrangecnt = 0;
                            pstockcnt = 0;
                            rstockcnt = 0;
                            if (this.$store.state.stock.length != 0){
                                this.$store.state.stock.forEach(element => {
                                    if(element.바코드 == ele.바코드){
                                        if(element.배치발주번호 == e.발주번호){
                                            arrangecnt++
                                        } else {
                                            if(element.배치발주번호 == ''){
                                                if(element.Stockinfo == 'stock') {
                                                    if(parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
                                                        notarrangecnt++
                                                    }
                                                } else {
                                                    if(element.Stockinfo == 'Pstock') {
                                                        pstockcnt++
                                                    } else {
                                                        if(element.Stockinfo == 'Rstock') {
                                                            if(parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
                                                                rstockcnt++
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                })
                            }
                            ele.발주수량 = parseInt(ele.발주수량);
                            ele.입고수량 = parseInt(ele.입고수량);
                            if(isNaN(ele.입고수량)){
                                ele.입고수량 = 0;
                            }
                            ele.확정수량 = parseInt(ele.확정수량);
                            ele.배치수량 = arrangecnt;
                            ele.미배치재고수량 = notarrangecnt;
                            ele.구매완료수량 = pstockcnt;
                            ele.회송예정수량 = rstockcnt;
                            ele.사입재고수량 = 0;
                            if(parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
                                this.$store.state.productdb.some(element => {
                                    if(ele.바코드 == element.바코드 && element.구매링크 == '사입'){
                                        ele.사입재고수량 = parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt;
                                        return (ele.바코드 == element.바코드 && element.구매링크 == '사입')
                                    }
                                })
                            }
                            ele.구매필요수량 = parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt - ele.사입재고수량;
                        })
                    })



                    if(this.totallists.length == 0){
                        this.showlist = false;
                        alert('생성할 리스트가 없습니다.')
                    } else {

                        this.totallists.forEach(e => {
                            addcnt = 0;
                            rstockcnt = 0;
                            stockcnt = 0;
                            sstockcnt = 0;

                            e.발주세부내역.forEach(element => {
                                existaddcnt = addcnt;
                                addcnt += parseInt(element.발주수량);
                                this.$store.state.productdb.some(el => {
                                    if(el.바코드 == element.바코드){
                                        if(el.구매링크 == '사입'){
                                            sstockcnt += element.발주수량;
                                            ssobj = {
                                                checked : false,
                                                발주번호 : e.발주번호,
                                                발주센터 : e.발주센터,
                                                입고예정일 : e.입고예정일,
                                                발주운송 : e.발주운송,
                                                SKUID : element.SKUID,
                                                바코드 : element.바코드,
                                                상품명 : element.상품명,
                                                발주수량 : element.발주수량,
                                                매입가 : element.매입가,
                                                공급가액 : element.공급가액,
                                                부가세액 : element.부가세액,
                                            };
                                            this.sstocklists.push(ssobj);
                                            addcnt -= parseInt(element.발주수량);
                                        }
                                        return (el.바코드 == element.바코드)
                                    }
                                })
                                if(existaddcnt < addcnt){
                                    this.$store.state.stock.some(ele => {
                                        if(ele.바코드 == element.바코드){
                                            if(ele.stockinfo == 'stock'){
                                                stockcnt++
                                                if(this.stocklists.length > 0){
                                                    elcnt = 0;
                                                    this.stocklists.forEach(el => {
                                                        if(el.발주번호 == e.발주번호 && el.바코드 == element.바코드){
                                                            el.수량++
                                                            elcnt++
                                                        }
                                                    })
                                                    if(elcnt == 0){
                                                        sobj = {
                                                            checked : false,
                                                            발주번호 : e.발주번호,
                                                            발주센터 : e.발주센터,
                                                            입고예정일 : e.입고예정일,
                                                            발주운송 : e.발주운송,
                                                            SKUID : element.SKUID,
                                                            바코드 : element.바코드,
                                                            상품명 : element.상품명,
                                                            발주수량 : element.발주수량, //표에서 hide
                                                            매입가 : element.매입가,
                                                            공급가액 : element.공급가액,
                                                            부가세액 : element.부가세액,

                                                            재고박스번호 : ele.재고박스번호, //표에서 show
                                                            재고파렛트번호 : ele.재고파렛트번호, //표에서 show
                                                            수량 : 1, //표에서 show
                                                        };
                                                        this.stocklists.push(sobj);
                                                    }
                                                } else {
                                                    sobj = {
                                                        checked : false,
                                                        발주번호 : e.발주번호,
                                                        발주센터 : e.발주센터,
                                                        입고예정일 : e.입고예정일,
                                                        발주운송 : e.발주운송,
                                                        SKUID : element.SKUID,
                                                        바코드 : element.바코드,
                                                        상품명 : element.상품명,
                                                        발주수량 : element.발주수량, //표에서 hide
                                                        매입가 : element.매입가,
                                                        공급가액 : element.공급가액,
                                                        부가세액 : element.부가세액,

                                                        재고박스번호 : ele.재고박스번호, //표에서 show
                                                        재고파렛트번호 : ele.재고파렛트번호, //표에서 show
                                                        수량 : 1, //표에서 show
                                                    };
                                                    this.stocklists.push(sobj);
                                                }

                                                addcnt--
                                                return (addcnt == existaddcnt)
                                            } else {
                                                if(ele.stockinfo == 'Rstock'){
                                                    rstockcnt++
                                                    if(this.rstocklists.length > 0){
                                                        elcnt = 0;
                                                        this.rstocklists.forEach(el => {
                                                            if(el.발주번호 == e.발주번호 && el.바코드 == element.바코드){
                                                                el.수량++
                                                                elcnt++
                                                            }
                                                        })
                                                        if(elcnt == 0){
                                                            robj = {
                                                                checked : false,
                                                                발주번호 : e.발주번호,
                                                                발주센터 : e.발주센터,
                                                                입고예정일 : e.입고예정일,
                                                                발주운송 : e.발주운송,
                                                                SKUID : element.SKUID,
                                                                바코드 : element.바코드,
                                                                상품명 : element.상품명,
                                                                발주수량 : element.발주수량, //표에서 hide
                                                                매입가 : element.매입가,
                                                                공급가액 : element.공급가액,
                                                                부가세액 : element.부가세액,

                                                                수량 : 1 //표에서 show
                                                            };
                                                            this.rstocklists.push(robj);
                                                        }
                                                    } else {
                                                        robj = {
                                                            checked : false,
                                                            발주번호 : e.발주번호,
                                                            발주센터 : e.발주센터,
                                                            입고예정일 : e.입고예정일,
                                                            발주운송 : e.발주운송,
                                                            SKUID : element.SKUID,
                                                            바코드 : element.바코드,
                                                            상품명 : element.상품명,
                                                            발주수량 : element.발주수량, //표에서 hide
                                                            매입가 : element.매입가,
                                                            공급가액 : element.공급가액,
                                                            부가세액 : element.부가세액,

                                                            수량 : 1 //표에서 show
                                                        };
                                                        this.rstocklists.push(robj);
                                                    }

                                                    addcnt--
                                                    return (addcnt == existaddcnt)
                                                }
                                            }
                                        }
                                    });
                                }
                                if(addcnt > 0){
                                    addobj = {
                                        checked : false,
                                        발주번호 : e.발주번호,
                                        발주센터 : e.발주센터,
                                        입고예정일 : e.입고예정일,
                                        발주운송 : e.발주운송,
                                        SKUID : element.SKUID,
                                        바코드 : element.바코드,
                                        상품명 : element.상품명,
                                        발주수량 : element.발주수량,
                                        매입가 : element.매입가,
                                        공급가액 : element.공급가액,
                                        부가세액 : element.부가세액,

                                        수량 : addcnt,
                                    }
                                    this.addlists.push(addobj);
                                }
                            })

                            obj = {
                                checked : false,
                                발주번호 : e.발주번호,
                                발주일시 : e.발주일시,
                                입고예정일 : e.입고예정일,
                                전체수량 : e.발주수량,
                                구매필요수량 : addcnt,
                                재고수량 : stockcnt,
                                사입재고수량 : sstockcnt,
                                회송재고수량 : rstockcnt,
                                발주세부내역 : e.발주세부내역
                            }
                            this.lists.push(obj);
                            this.showlist = true;
                        })

                        //리스트
                        //발주번호 - 전체수량 x개, 필요수량 x개, 회송재고 x개, 재고 x개, 사입재고 x개, 구매링크 필요수 x개 (상품DB 업데이트 필요))
                    }
                } else {
                    this.submitStatus = false;
                    alert('주문서 생성 실패')
                }
            } else {
                alert('1688주문서를 생성하려면 Products > 상품DB등록 메뉴에서 ' + this.poinfocnt + '개의 상품DB에 대한 구매정보(구매링크,중국어옵션명)를 업데이트 해주세요')
            }
        },
        savesetting() {
            var payload = {
                companyid : this.$store.state.user.companyid,
                CreatePo_수취인 : this.CreatePo_수취인,
                CreatePo_연락처 : this.CreatePo_연락처,
                CreatePo_주소_성 : this.CreatePo_주소_성,
                CreatePo_주소_시 : this.CreatePo_주소_시,
                CreatePo_주소_구 : this.CreatePo_주소_구,
                CreatePo_상세주소 : this.CreatePo_상세주소,
                CreatePo_주문번호 : this.CreatePo_주문번호,
                CreatePo_발주기준일 : this.CreatePo_발주기준일
            };

            this.$store.commit('settingsupdate', payload)
            console.log(this.$store.state.settings)
            axios.post('/api/addpurchase/addsettings',payload)
            .then((res) => {alert(res.data.요청결과)})
        }
    },
    mounted() {
        this.$nextTick(function () {
            this.getreqlists();
            this.usergrade()
        })
    }
}
</script>
<style>
.my-class .dropdown-menu {
transform: translate3d(0px, -30px, 0px) !important;
max-height: 300px;
width:400px;
z-index:10000;
overflow-y: auto;
}
#quill-container{
    height: 50px;
}
#quill-container .ql-editor{
    min-height: unset;
}

.modal-dialog {
    max-width: 90%;
    position: absolute;

    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.action-button {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.warning2 {
    font-size: 16px;
    color: #e83e8c;
    font-weight: bold;
}
.status {
    width: 100%;
    border: 3px solid #cccccc;
    display: flex;
    justify-content: space-between;
}
.status-area {
    width: 30%;
    padding: 20px;
}
.status-header {
    border-bottom: 1px solid #ccced2;
    text-align: center;
    margin-bottom: 12px;
}
.status-tit {
    margin-bottom: 20px;
}
.status-txt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.status-txt a{
    cursor: pointer;
}
.status-txt span {
    font-weight: bold;
}

</style>
